/** @format */

import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { navigate } from 'gatsby-link';
import { globalHistory } from '@reach/router';
import resume from '../pdfs/resume.pdf';

const path = globalHistory.location.pathname;

const Navbar = ({ location, data }) => {
    const path = globalHistory.location.pathname;

    return (
        <div className="section">
            <div className="container">
                <div className="navbar-wrapper">
                    <div
                        role="button"
                        onClick={() => navigate('/')}
                        className="name"
                        tabIndex={0}>
                        Ian's Portfolio.
                    </div>

                    <div className="links-wrapper">
                        <button
                            onClick={() => {
                                path === '/'
                                    ? scrollTo('#work')
                                    : navigate('/#work');
                            }}>
                            Work
                        </button>
                        <button onClick={() => navigate('/about')}>
                            About
                        </button>
                        <button onClick={() => window.open(resume, '_blank')}>
                            Resume
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
