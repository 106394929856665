import React from "react"

import Layout from "../components/layout"
import About from "../components/about"
import Footer2 from "../components/Footer2"

const AboutPage = () => (
    <Layout>
        <About></About>
        <Footer2></Footer2>
    </Layout>
)

export default AboutPage